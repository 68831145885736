import React from 'react'
import RatingSlider from './RatingSlider'

function Rating() {
  return (
    <div className='mt-24 '>
        <h1 className=' wow  animate__animated animate__fadeInLeft text-back-color2 text-[18px] md:text-[40px] font-[800] text-center'>Don't take our word. Take theirs</h1>
        <p className=' wow  animate__animated animate__fadeInLeft mt-3 text-center text-[16px] sm:text-[18px] text-[#808285]'>Here are some reviews from some of our esteemed customers.</p>
        <div>
            <RatingSlider/>
        </div>
    </div>

  )
}

export default Rating
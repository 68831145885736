import React from 'react'

function Content() {
  return (
    <div className=' my-5  sm:my-10 px-10 sm:px-20'>
        <div className=' mb-7'>
                <p className=' font-[500] text-back-color2 sm:text-[20px]'>The Terms of Service govern your use of the services provided by Zouse, our websites, (collectively referred to as the "Service"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree with these Terms, you may not use the Service.</p>
        </div>
        <div className=' mb-7'>
            <p className=' font-[700] text-primary-color sm:text-[20px] mb-3 '>(1 PART) Age Restriction:</p>
            <p className=' font-[500] text-back-color2 sm:text-[20px]'>You must be at least 18 years old or have reached the age of majority in your jurisdiction to use the Service. If you are using the Service on behalf of a company or organization, you represent and warrant that you have the necessary authority to bind such company or organization to these Terms.</p>
        </div>
        <div className=' mb-10'>
            <p className=' font-[700] text-primary-color sm:text-[20px] mb-3 '>(2 PART) User Accounts:</p>
            <p className=' font-[500] text-back-color2 sm:text-[20px]'>You may need to create a user account to access certain features of the Service. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to provide accurate and complete information when creating your account and to promptly update any information that may change.</p>
        </div>
        <div className=' mb-[48px]'>
            <p className=' font-[700] text-primary-color sm:text-[20px] mb-3 '>(3 PART) Prohibited Activities:</p>
            <p className=' font-[500] text-back-color2 sm:text-[20px]'>While using the Service, you agree not to engage in any of the following activities:</p>
            <ol className="list-lower-alpha list-inside">
                <li className=' font-[500] text-back-color2 sm:text-[20px] pb-3'>a) Violating any applicable laws, regulations, or third-party rights;</li>
                <li className=' font-[500] text-back-color2 sm:text-[20px] pb-3'> b) Circumventing, disabling, or interfering with any security features of the Service;</li>
                <li className=' font-[500] text-back-color2 sm:text-[20px] pb-3'>c) Modifying, adapting, translating, reverse-engineering, decompiling, or disassembling any portion of the Service;</li>
                <li className=' font-[500] text-back-color2 sm:text-[20px] pb-3'>d) Uploading, posting, or transmitting any content that is unlawful, harmful, defamatory, obscene, or otherwise objectionable;</li>
                <li className=' font-[500] text-back-color2 sm:text-[20px] pb-3'> e) Using the Service to send unsolicited commercial communications (spam);</li>
                <li className=' font-[500] text-back-color2 sm:text-[20px] pb-3'>f) Impersonating any person or entity or falsely stating or otherwise misrepresenting your affiliation with a person or entity;</li>
                <li className=' font-[500] text-back-color2 sm:text-[20px] pb-3'>g) Collecting or harvesting any personally identifiable information from the Service;</li>
                <li className=' font-[500] text-back-color2 sm:text-[20px] pb-3'> h) Interfering with or disrupting the Service or servers or networks connected to the Service; </li>
                <li className=' font-[500] text-back-color2 sm:text-[20px] pb-3'>i) Engaging in any other activity that we determine, in our sole discretion, to be detrimental to the Service or our users.</li>

            </ol>
            <p className=' font-[500] text-back-color2 sm:text-[20px] pb-3'>For other purposes which we will notify you about and seek your consent.</p>
        </div>
       <div className=' mb-[48px]'>
            <p className=' font-[700] text-primary-color sm:text-[20px] mb-3 '>Contacting us</p>
            <p className=' font-[500] text-back-color2 sm:text-[20px] pb-3'>If you have any questions about this Privacy Policy, the practices of this site,</p>
            <p className=' font-[500] text-back-color2 sm:text-[20px] pb-3'> or your dealings with this site, please contact us at:</p>
            <p className=' font-[700] text-back-color2 sm:text-[20px] pb-8'> Zousellc</p>
            <p className=' font-[500] text-back-color2 sm:text-[20px]  pb-3'> info@zousellc.ng</p>
       </div>
    </div>
  )
}

export default Content
import React, { useEffect, useState } from 'react'
import InvestmentComponent from "./InvestmentComponent";
import getServices from "../../Services/getServices";
import House from '../../Assets/images/house.png';
function Investment() {
  const [data, setData] = useState([])
  let counter = 0;
   const getData = () =>{
          getServices.getAllInvestments()
        .then(response => {
          // Handle the successful response
          setData(response.data.data)
          console.log(response.data);
        })
        .catch(error => {
          // Handle the error
          console.error(error);
        });
   }
   useEffect(() => {
    getData()
   }, [])
   const filteredData = data.filter(item => item.projectStatus === 'active');
  return (
    <div>
        <div>
         <div className=" md:mt-10 flex mb-6 mt-4 justify-between">
       <h1 className=" text-[16px] mb-3 pl-5 md:pl-0 md:text-[32px] font-[800] text-back-color2 ">Recent Investment opportunities</h1>
      
       </div>
           <div className=' grid sm:grid-cols-3 gap-3'>
           {filteredData.map((items, index) =>{
            if (counter < 3) {
          counter++;
          return  <div key={index}>
              <InvestmentComponent
                name={items.projectName}
                location={items.projecLocation ?? 'No location'}
                raised={items.pricePerUnit * items.unitSold}
                target= {items.projectPriceTotal}
                percent={Math.ceil(items.percentAvailable)}
                image={items.imageUrl[0] ?? House}
              />
             </div>
            }
             return null
           })}
           </div>
          {filteredData  === 0 && <p className=' text-center text-red-700 text-2xl'> No active investment currently</p>}
        
         </div>
    </div>
  )
}

export default Investment

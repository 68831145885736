import React from 'react'
import R1 from '../../Assets/images/bloog1.jpeg'
import R2 from '../../Assets/images/bloog2.jpeg'
import R3 from '../../Assets/images/r3.png'
import BlogComponent from './BlogComponent'
function RecentBlog() {
  return (
    <div className=' bg-back-color2 py-5 md:py-10 px-2 md:px-20 mt-10'>
        <h1 className=' text-[40px] font-[800]  text-white pl-3'>Recent posts</h1>

        <div className=' grid md:grid-cols-3 gap-4'>
            <BlogComponent
                img={R1}
                header='Simplifying Rentals: Enhancing Landlords’ and Tenants’ Experience with Zouse'
                link='https://zousellc.medium.com/simplifying-rentals-enhancing-landlords-and-tenants-experience-with-zouse-6729333364c5'
                date='JUN 14, 2023'
                sub='Renting a property can be a complex process for both landlords and tenants. However, with the advent of PropTech solutions, the rental experience is being transformed.'
                headerColor=' text-white'
                dateColor=' text-[#D5D5D6]'
                subColor=' text-[#D5D5D6]'
            />
             <BlogComponent
                img={R2}
                header='Securing Your Investments: Exploring the Security Features in PropTech'
                date='JUN 14, 2023'
                link='https://zousellc.medium.com/securing-your-investments-exploring-the-security-features-in-proptech-e303d1564ec'
                sub='Investing in real estate is a significant decision, and ensuring the security of your investments is paramount. In this blog post, we’ll dive into the security features of our Zouse and how they safeguard your investments, providing you with peace of mind.'
                headerColor=' text-white'
                dateColor=' text-[#D5D5D6]'
                subColor=' text-[#D5D5D6]'
            />
             {/* <BlogComponent
                img={R3}
                header='THE NOTION OFTHE ORIGINAL: IN CONVERSATION WITH FILMMAKER WIM WENDERS'
                date='JUN 7, 2022'
                sub='Will the taste of a cool crisp coke or an ice cold beer still taste as sweet in the metaverse? Let’s find out.'
                headerColor=' text-white'
                dateColor=' text-[#D5D5D6]'
                subColor=' text-[#D5D5D6]'
            /> */}
        </div>
    </div>
  )
}

export default RecentBlog
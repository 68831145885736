import React from 'react'
import Ques from '../../Assets/images/que.svg'
function TopSec() {
  return (
    <div className=' pt-7 px-2 md:px-0   w-full'>
        <div className='   wow  animate__animated animate__fadeInLeft   '>
            <div className=' bg-back-color2 p-20 sm:p-32 '>
            <div>
                  <div className=' flex sm:justify-center'>
                  <div className=''>
                  <h1 className=' inline-block sm:text-[48px] text-white text-center text-[24px] mb-2  font-[800]'>Get your questions answered</h1> <img className=' inline-block' src={Ques} alt='ques'/>
                  </div>
                  </div>
                   
                    <p className=' text-white text-center '>Find answers to the most commonly asked questions below. Search for topics you're interested in</p>
            </div>
            </div>
            
        </div>
    </div>
  )
}

export default TopSec
import React from 'react'
import About from '../../Assets/images/about.png'
function Aboutuss() {
  return (
    <div className=' mt-10 grid gap-5 md:grid-cols-2'>
        <div>
            <img src={About} className='wow  animate__animated animate__zoomIn' alt='About'/>
        </div>
        <div className=' wow  animate__animated animate__fadeInLeft mt-10'>
            <h1 className=' text-[20px] sm:text-[40px] font-[800] mb-5 text-back-color2'>About us</h1>
            <p className='text-[18px] font-[400] mb-5 text-back-color2'>The product and company known as Zouse today began with an idea that sparked in 2014. We realized that due to currency shocks, high inflation rates and the impact of devaluation, most investment opportunities across emerging markets do not yield enough returns for investors to grow their wealth. This makes it difficult for people in these markets to achieve their financial goals.
            <br></br><br></br>
            To solve this problem, we built Zouse as a simple way of connecting our users to  investments in developed markets, managed by a world class team using smart algorithms to guide our decisions, while helping our users become more financially literate.</p>
        </div>
    </div>
  )
}

export default Aboutuss
import React from 'react'
import TwoCom from './TwoCom'
import MImage from '../../Assets/images/aas.png'
import Button from '../SelectValue/Button'
import Constant from '../../Constant'
function BuyInvest() {
  return (
    <div className=' p-8 md:p-20 grid sm:grid-cols-2 gap-4'>
    <div className=' sm:hidden block'>
            <div>
                <img src={MImage} alt='alt'/>
            </div>
        </div>
        <div>
            <h1 className=' text-[24px] md:text-[48px] text-back-color2 mb-3 font-[800]'>Buy & invest in properties</h1>
            <p className=' text-[14px] md:text-[18px] text-back-color2 mb-3 font-[400]'>Invest in income generating properties with zouse - The most transparent real estate crowdfunding platform with carefully selected and pre-vetted real estate investment opportunities in Nigeria.</p>
            <div>
            <TwoCom
                name='Minimal investment fees'
            />
             <TwoCom
                name='Prevetted Investments only'
            />
             <TwoCom
                name='Private and business accounts'
            />
             <TwoCom
                name='NGN 5000 minimum investment'
            />
        </div>
        <div className=' mt-4'>
        <a href={Constant.BASE_LINK_URL + '/'} target='_blank' rel="noreferrer">
            <Button
                name='Get started'
            />
            </a>
        </div>
        </div>
       
        <div className=' hidden sm:block'>
            <div>
                <img src={MImage} alt='alt'/>
            </div>
        </div>
    </div>
  )
}

export default BuyInvest
import React from 'react'
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Constant from '../../Constant';
function ListingCom({onClick, name, img, desc, start, price, location,count, area, plot}) {
  return (
    <div className=' my-4 wow animate__zoomInUp animate__animated'>
    <div className=' border-t shadow-lg border-x mt-6 border-x-primary-color5 border-t-primary-color5 rounded-t-lg p-4'>
        <p className=' mb-3 text-back-color2'>{plot} Plot Of Land For Purchase</p>
        <div className=' grid grid-cols-3 gap-3'>
            <div className=' relative'>
                <img src={img} className='h-32' alt='List'/>
                <div className=' bg-white p-1 absolute top-2 left-2 rounded'>
                    <p className=' text-xs'> <PhotoCameraOutlinedIcon/> {count}</p>
                </div>
            </div>
            <div className=' col-span-2'>
                <p className=' text-primary-color text-[14px] mb-2 font-[500]'> {name} for Sale</p>
                <p className=' font-[500] text-back-color2 mb-2 text-[14px] '><PlaceOutlinedIcon className=' text-back-color2'/> {location}</p>
                <p className=' text-[14px] text-[400]'>{desc} ..<span onClick={onClick} className=' cursor-pointer text-primary-color'>More details</span></p>
                <p className=' pb-2 border-b border-primary-color5 text-xs mt-1'> <CalendarMonthOutlinedIcon/> <span className=' pt-2'> Added on {start}</span></p>
                <p> <span className=' text-primary-color text-[14px] font-[500]'>NGN {price}</span> per plot</p>
                
            </div>

        </div>
    </div>
    <div className=' p-3 border border-primary-color5 bg-[#F8FAFC] rounded-b-lg'>
        <div className=' flex justify-between'>
            <p className=' text-xs pt-2 font-normal text-back-color2'>{area}sqm total area</p>
            <div>
               <a href={Constant.BASE_LINK_URL + '/'} target='_blank' rel='noreferrer'><p className=' text-primary-color text-[16px] font-medium'> Sign in to see details</p></a> 
            </div>
        </div>
    </div>
    </div>
  )
}

export default ListingCom
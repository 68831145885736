import React, { useEffect } from 'react'
import Footer from '../../Components/Footer'
import NeedFunding from '../../Components/Funding/NeedFunding'
import Start from '../../Components/Funding/Start'
import TopSec from '../../Components/Funding/TopSec'
import Why from '../../Components/Funding/Why'
import Download from '../../Components/Home/Download'
import NavBar from '../../Components/Navbar'
import WOW from 'wowjs';
function GetFunding() {
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])
  return (
    <div>
        <NavBar/>
        <div className=' px-2 md:px-20'>
            <TopSec/>
           
        </div>
        <NeedFunding/>
        <Why/>
        <div className=' px-2 md:px-20'>
           <Download/>
           <Start/>
        </div>
        <Footer/>
    </div>
  )
}

export default GetFunding
import React from 'react'
import Icon from '../../Assets/images/whyicon.png'
function WhyComponent({header, sub}) {
  return (
    <div className='wow animate__zoomIn animate__animated flex my-3'>
        <div className='flex-none'>
            <img src={Icon} className='pt-2' alt='icon'/>
        </div>
        <div className='grow  pl-2'>
            <h1 className=' text-[#F7F8FB] pt-1 font-[700] mb-2'>{header}</h1>
            <p className=' text-primary-color5'>{sub}</p>
        </div>
    </div>
  )
}

export default WhyComponent
// import React from 'react'
// import HowInvestCom from './HowInvestCom'

// function HowInvest() {
//   return (
//     <div className=' mt-32'>
//         <HowInvestCom/>
//     </div>
//   )
// }

// export default HowInvest

import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HowInvestCom from './HowInvestCom'
import HowInvestCom1 from "./HowInvestCom1";

function SampleNextArrow(props) {
  return (
    <div 
    />
  );
}

function SamplePrevArrow(props) {
  return (
    <div
    />
  );
}

export default class HowInvest extends Component {
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
    next() {
      this.slider.slickNext();
    }
    previous() {
      this.slider.slickPrev();
    }
    render() {
      const settings = {
        
        fade: true,
        infinite: true,
        autoplay: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
      return (
        <div className=" ">
       
          <Slider ref={c => (this.slider = c)} {...settings}>
            <div className=" bg-[#CCF2E180] h-full" key={1}>
            <HowInvestCom/>
            </div>
            <div className="bg-[#FCDBB4] h-full" key={2}>
          <HowInvestCom1/>
            </div>
          </Slider>
        
        </div>
      );
    }
  }

import React from 'react'
import NavBar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import Topsec from '../../Components/Terms/Topsec'
import Content from '../../Components/Terms/Content'

function Terms() {
  return (
    <div>
        <NavBar/>
        <Topsec/>
        <Content/>
        <Footer/>
    </div>
  )
}

export default Terms
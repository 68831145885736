import React, { useEffect } from 'react'
import Footer from '../../Components/Footer'
import Download from '../../Components/Home/Download'
import NewInvestment from '../../Components/Invest/NewInvestment'
import TopSec from '../../Components/Invest/TopSec'
import NavBar from '../../Components/Navbar'
import WOW from 'wowjs';
function Invest() {

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])
  return (
    <div>
        <NavBar/>
        <div className=' px-2 md:px-20'>
            <TopSec/>
           
        </div>
        <NewInvestment/>
        <div className=' '>
            <Download/>
           
        </div>
        <Footer/>
    </div>
  )
}

export default Invest
import React from 'react'

function Topsec() {
  return (
    <div className=' term-bg grid h-full pt-48 sm:pt-60  relative pb-10 sm:p-48 place-items-center'>
    <div>
<h1 className=' text-center text-white font-[800] md:text-[56px] text-[24px] sm:text-[32px] pb-3'>Privacy policy</h1>
</div>
</div>
  )
}

export default Topsec
import React, { useState } from 'react'
import Add from '../../Assets/images/add.svg'
import Sub from '../../Assets/images/cancel.svg'
function FaqCom({header, content}) {
    const [expanded, setExpanded] = useState(false);
    const handleToggle = () => {
        setExpanded(!expanded);
      };
  return (
    <div className={expanded ? ' border relative rounded-[16px] border-primary-color p-6' : 'border relative rounded-[16px] border-[#E6E6E7] p-6'}>
        <h1 className=' text-[24px] font-[700] mb-3 text-back-color2'>{header}</h1>
        {content.length <= 140 || expanded ? (
            <div>
        <p>{content}</p>
        <button className=' text-primary-color underline  text-[18px] font-[500]' onClick={handleToggle}>
            {expanded ? "See Less" : "See More"}
          </button>
        </div>
      ) : (
        <div>
          <p>{content.slice(0, 140)}...</p>
          <button className=' text-primary-color underline  text-[18px] font-[500]' onClick={handleToggle}>
            {expanded ? "See Less" : "See More"}
          </button>
        </div>
      )}

        <div className=' absolute bottom-3 right-3'>
            {expanded ? <img onClick={handleToggle}  src={Sub} alt='Sub'/> : <img onClick={handleToggle} src={Add} alt='add'/>}
        </div>
    </div>
  )
}

export default FaqCom
import React, {useEffect, useState} from 'react'
import Logo from '../Assets/images/Zlogo.png'
import Facebook from '../Assets/images/facebook.png'
import LinkedIn from '../Assets/images/lin.png'
import Twitter from '../Assets/images/twitter.png'
import IG from '../Assets/images/ig.png'
import FooterInput from './SelectValue/FooterInput'
import { Link } from 'react-router-dom'
function Footer() {
        const [year, setYear] = useState('')

        useEffect(() =>{
            const date = new Date();
            setYear(date.getFullYear())
        }, [])

  return (

    <div className=' border bg-footer-bg bordet-t-[#E6E6E7] mt-20 pb-6 pt-24 px-8 md:px-24'>
        <div className=' grid lg:grid-cols-7 gap-4'>
            <div className=' col-span-2'>
                    <img src={Logo} alt='Logo'/>
                    <p className='text-back-color2 mt-3 md:pr-6'>Invest in income generating properties with zouse - The most transparent real estate platform.</p>
            </div>
            <div className=' block md:hidden col-span-2'>
            <h1 className=' font-[700] text-[18px] text-back-color2 mb-4'>Get Our newsletter</h1>
            <p className=' text-back-color2 mb-4 md:pr-16'>Be the first to receive updates and notifications from us</p>
            <FooterInput/>
            </div>
            <div>
                <h1 className=' font-[700] text-[18px] text-back-color2 mb-4'>Company</h1>
               <Link to='/aboutus'><p className=' text-back-color2 mb-2'>About Us</p></Link> 
               <Link to='/blog'><p className=' text-back-color2 mb-2'>Blog</p></Link> 
               <Link to='/'><p className=' text-back-color2 mb-2'>Testimonials</p></Link> 
               <Link to='/faq'><p className=' text-back-color2 mb-2'>FAQ</p></Link> 
            </div>
            <div>
                <h1 className=' font-[700] text-[18px] text-back-color2 mb-4'>How it works</h1>
                <Link to='/invest'><p className=' text-back-color2 mb-2'>Investment</p></Link>  
                <Link to='/listing'> <p className=' text-back-color2 mb-2'>Listing</p></Link>  
            </div>
            <div>
                <h1 className=' font-[700] text-[18px] text-back-color2 mb-4'>Terms of use</h1>
                <Link to='/privacy'><p className=' text-back-color2 mb-2'>Privacy Policy</p></Link>  
                <Link to='/terms'><p className=' text-back-color2 mb-2'>Terms of service</p></Link> 
                
            </div>
            <div className=' hidden md:block col-span-2'>
            <h1 className=' font-[700] text-[18px] text-back-color2 mb-4'>Get Our newsletter</h1>
            <p className=' text-back-color2 mb-4 md:pr-16'>Be the first to receive updates and notifications from us</p>
            <FooterInput/>
            </div>

        </div>
        <div className='  mt-10 border-t bordet-t-[#E6E6E7] '>
            <div className=' flex justify-between pt-5 md:pt-10'>
            <p className=' text-primary-color6'>{year}  © Zouse integrated Limited. All rights reserved.</p>
            <div>
            <div className=' flex'>
                <div className=' p-2'>
                
                <a href='https://web.facebook.com/zousellc' target='_blank' rel='noreferrer'>
                    <img src={Facebook} alt='Facebook'/>
                    </a>
                </div>
                <div className=' p-2'>
                <a href='https://instagram.com/zousellc?igshid=NGExMmI2YTkyZg==' target='_blank' rel='noreferrer'>
                    <img src={IG} alt='IG'/>
                    </a>
                </div>
                <div className=' p-2'>
                <a href='https://www.linkedin.com/company/zousellc/' target='_blank' rel='noreferrer'>
                <img src={LinkedIn} alt='LinkedIn'/>
                    </a>
                   
                </div>
                <div className=' p-2'>
                
                <a href='https://twitter.com/zousellc?t=5x06vWL-Tuhk8KUWUd-o4g&s=09' target='_blank' rel='noreferrer'>
                    <img src={Twitter} alt='Twitter'/>
                </a>
                </div>
            </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Footer
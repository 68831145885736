import axios from "axios";

const getAllInvestments = () => {
    return axios.get( "/api/return-projects");
}
const getAllListing = () => {
    return axios.get( "/api/return-properties");
}
const sendEmail = (emailAddress, firstName, lastName, message) =>{
    const value = {"emailAddress":emailAddress, "firstName": firstName, "lastName": lastName, "message": "message",}

    return axios.post('/api/contact-us', value)
}



const getServices = {
        getAllInvestments,
        getAllListing, 
        sendEmail
}

export default getServices;
import React from 'react'
import WhyComponent from './WhyComponent'

function Why() {
  return (
    <div className='  mt-32 why-bg p-8 md:p-20'>
        <div className=''>
            <h1 className=' text-[#F7F8FB] font-[800] wow  animate__animated animate__fadeInLeft text-[40px] mb-5'>Why you should invest with zouse</h1>
            <p className=' text-primary-color5 wow  animate__animated animate__fadeInLeft xl:pr-[780px]'>Trust is our currency. We are committed to the security of your money and the protection of your account.</p>
            <div className=' mt-10 grid md:grid-cols-3 lg:grid-cols-4 gap-4'>
                <WhyComponent
                    header='High tax breaks and deductions'
                    sub='Deduct the reasonable costs of owning, operating and managing a property. Lets do that for you.'
                />
                <WhyComponent
                    header='Guaranteed property Appreciation'
                    sub='Real estate investors make money through rental income, any profits generated by property.'
                />
                <WhyComponent
                    header='Compertitive Risk-adjusted returns'
                    sub='Real estate returns vary, depending on factors such as location, asset class, and management. '
                />
                <WhyComponent
                    header='Build equity and massive wealth'
                    sub="As you pay down a property mortgage, you build equity—an asset that's part of your net worth. "
                />
            </div>
        </div>
    </div>
  )
}

export default Why
import React from 'react'
import Circle from '../../Assets/images/c.png'
function BlogComponent({img, header, date, link, sub, headerColor, dateColor, subColor}) {
  return (
    <div className=' m-4 wow  animate__animated animate__zoomIn'>
    <a href={link} target='_blank' rel='noreferrer'>
    <img src={img} alt='img' className=''/>
        <p className={`font-[500] text-2xl ${headerColor} mt-2 mb-3`}>{header}</p>

        <p className={ `${dateColor} mb-2`}>{date} <img src={Circle} alt='Cirle' className='m-2 inline-block'/>ZOUSE BLOG</p>
        <p className={ `${subColor} text-[18px] mb-2`}>{sub}</p>
    </a>
       
    </div>
  )
}

export default BlogComponent 
import React, { useEffect } from 'react'
import BlogPost from '../../Components/Blog/BlogPost'
import Invest from '../../Components/Blog/Invest'
import Money from '../../Components/Blog/Money'
import RecentBlog from '../../Components/Blog/RecentBlog'
import Footer from '../../Components/Footer'
import NavBar from '../../Components/Navbar'
import WOW from 'wowjs';
function Blog() {
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])
  return (
    <div>
       <NavBar/>
       <BlogPost/>
       <RecentBlog/>
       {/* <Invest/>
       <Money/> */}
       <Footer/> 
    </div>
  )
}

export default Blog
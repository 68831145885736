import React, { useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import RoomIcon from '@mui/icons-material/Room';
const LocationMap2 = ({ latitude, longitude }) => {
    const markerRef = useRef(null);

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.setLatLng([latitude, longitude]);
    }
  }, [latitude, longitude]);
    return (
      <MapContainer center={[latitude, longitude]} zoom={15} style={{ height: '400px', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker  ref={markerRef} position={[latitude, longitude]}>
          <Popup>
           <RoomIcon className=' text-red-600'/>
          </Popup>
        </Marker>
      </MapContainer>
    );
  };

  export default LocationMap2
import React from 'react'

function ContactCom({name, address, email, pNum,bool, change}) {
  return (
    <div onClick= {change} className={bool ? ' rounded-[12px] w-full border-2 px-3 border-[#00BC67] bg-[#F5F5F5] inline-block shadow shadow-gray-200 py-2 my-4' : 'py-2 bg-white w-full border border-[#D2D3D5] px-3 inline-block my-4 '}>
        <h1 className=' text-[24px] font-[700] text-back-color2 mb-3'>{name}</h1>
        <p className='text-[14px] font-[400] text-back-color2 mb-3'><span className=' font-[500]'>Address:</span> {address}</p>
        <p className='text-[14px] font-[400] text-back-color2 mb-3'><span className=' font-[500]'>Phone number:</span> {pNum}</p>
        <p className='text-[14px] font-[400] text-back-color2 mb-3'><span className=' font-[500]'>Email:</span> {email}</p>
    </div>
  )
}

export default ContactCom
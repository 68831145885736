import React from 'react'
import Missionn from '../../Assets/images/mission.png'
function Mission() {
  return (
    <div className=' grid mt-10 md:grid-cols-2 gap-5'>
        <div className='wow  animate__animated animate__fadeInLeft'>
        <h1 className='text-[20px] sm:text-[40px] font-[800] mb-5 text-back-color2'>Our mission</h1>
            <p className=' text-[20px] font-[400] text-back-color2'>At Zouse, our mission is to empower individuals and organizations to participate in the dynamic world of real estate through a cutting-edge crowdfunding and investment platform. We believe that everyone should have the opportunity to invest in real estate and benefit from the financial rewards and diversification it offers.
            <br></br><br></br>
We are committed to democratizing real estate investment by providing a transparent, accessible, and user-friendly platform that connects investors with carefully curated real estate opportunities. Our platform leverages the power of technology, data-driven insights, and industry expertise to enable seamless transactions and foster mutually beneficial relationships between investors and real estate projects.</p>
        </div>
        <div>
          <img src={Missionn} className=' w-full wow  animate__animated animate__zoomIn' alt='Mission'/>  
        </div>
    </div>
  )
}

export default Mission
import React from 'react'
import Constant from '../../Constant'
import Button2 from '../SelectValue/Button2'
import HowCountCom from './HowCountCom'

function HowInvestCom() {
  return (
    <div className={` bg-[#CCF2E180] w-full h-full  py-16  grid lg:grid-cols-2 px-5 gap-5 sm:px-5`}>
        <div className=' flex xl:w-[75%] mx-auto px-5 sm:px-10 justify-center'>
        <div>

       
            <h1 className=' text-[18px] font-[800] mb-3 sm:text-[28px] lg:text-[32px]'>How zouse works: Investment</h1>

            <div className=' bg-[#AAE9CC] p-3 border-r-8 border-b-8  px-10 py-5 border-[#00BC67] rounded-[27px] '>
                    <p className=' text-[18px] mb-3 sm:text-[32px] text-back-color2 font-[400]'>Invest with zouse through crowdfunding</p>
                    <p className=' text-[18px] sm:text-[24px] mb-4 text-back-color2 font-[400]'>Join other investors to raise funds for projects and earn ROI based on your investment.</p>
                    <div className=' mb-6'>
                    <a href={Constant.BASE_LINK_URL + '/'} target='_blank' rel="noreferrer">
                    <Button2
                    name='Get started'
                     />
                    </a>
                   
                    </div>
                   
           </div>
            </div>
        </div>
        
        <div className=' px-2 xl:px-5'>
        <div>
        <HowCountCom
            num='01'
            header='Platform Registration'
            sub= {<p>
              Investors, property owners, and developers join Zouse, sharing financial info and preferences to match with suitable partners seeking funding.
            </p>}
           
            color=' text-primary-color'
          />  
           <HowCountCom
            num='02'
            header='Investment Opportunities'
            sub= {<p>
                Investors browse curated opportunities, review project details including plans, financial projections, and risk assessments, and choose investments that meet their criteria.
            </p>}
           
            color=' text-primary-color'
          />  
           <HowCountCom
            num='03'
            header='Funding and Returns'
            sub= {<p >
              Investors fund selected projects, securely transferring funds to property owners/developers. They receive updates on project progress and expect returns like rental income, capital appreciation, or profit shares upon completion.
            </p>}
            color=' text-primary-color'
          />  
         
        </div>
         
        </div>
    </div>
  )
}

export default HowInvestCom
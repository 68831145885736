import React from 'react'

function ThirdButton({name, onClick}) {
  return (
    <>
    <button 
     className=' text-primary-color  font-medium border-2  border-gray-200 rounded-lg  px-10 py-2 text-center  '
     onClick={onClick}
     type='submit'
    >{name}</button>   
   </>
  )
}

export default ThirdButton
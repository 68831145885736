import React from 'react'
import Constant from '../../Constant'
import Button2 from '../SelectValue/Button2'
import HowCountCom from './HowCountCom'

function HowInvestCom1() {
  return (
    <div className={` bg-[#FDEAD2] w-full h-full  py-16  grid lg:grid-cols-2 px-5 gap-5 sm:px-5`}>
        <div className=' flex xl:w-[75%] mx-auto px-5 sm:px-10 justify-center'>
        <div>

       
            <h1 className=' text-[18px] font-[800] mb-3 sm:text-[28px] lg:text-[32px]'>How zouse works: Listing</h1>

            <div className=' bg-[#FCDBB4] p-3 border-r-8 border-b-8  px-10 py-5 border-[#F7941D] rounded-[27px] '>
                    <p className=' text-[18px] mb-3 sm:text-[32px] text-back-color2 font-[400]'>Expand your reach to thousands of investors</p>
                    <p className=' text-[18px] sm:text-[24px] mb-4 text-back-color2 font-[400]'>Sign up as a business and get your properties seen buy thousands of investors on our platform</p>
                    <div className=' mb-6'>
                    <a href={Constant.BASE_LINK_URL + '/'} target='_blank' rel="noreferrer">
                    <Button2
                    name='Get started'
                     />
                     </a>
                    </div>
                   
           </div>
            </div>
        </div>
        
        <div className=' px-2 xl:px-5'>
        <div>
        <HowCountCom
            num='01'
            header='Property Listing'
            sub={<p>
              Investors, property owners, and developers join Zouse, sharing financial info and preferences to match with suitable partners seeking funding.
            </p>} 
            color=' text-[#F7941D]'
          />  
           <HowCountCom
            num='02'
            header='Investor Evaluation'
            sub= {<p>"Potential investors can access property listings, review details, evaluate investment opportunities, and make informed decisions based on preferences and investment criteria.</p>} 
            color=' text-[#F7941D]'
          />  
           <HowCountCom
            num='03'
            header='Streamlined Process'
            sub={<p className=' pb-5 xl:pb-0'>
              The Zouse solution enables efficient matching of investors with suitable property listings, streamlines transactions, and ensures transparent communication, providing a seamless experience for property owners/developers and investors.
            </p>}
            color=' text-[#F7941D]'
          />  
        </div>
         
        </div>
    </div>
  )
}

export default HowInvestCom1
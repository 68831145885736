import React from 'react'

function Textarea ({label, onChange, value}) {
  return (
    <div className='my-2'>
      <label  className=' text-back-color2 block mb-2' >{label}</label>
      <textarea minLength={3} 
      className=' w-full border p-6 border-gray-300 rounded-lg'
      onChange={(e) => onChange(e.target.value)}
      value={value}
      ></textarea>
   
    </div>
  )
}

export default Textarea

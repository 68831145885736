import React from 'react'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
// import House from '../../Assets/images/house.png';
import Progress from './Progress';

function InvestmentComponent({name, location, percent, raised, target, image}) {
  return (
    <div className=' p-2 mx-5 border-gray-200 shadow rounded-lg '>
        <div>
            <img src={image} className=' w-full h-[250px]' alt='House'/>
            <div className=' flex justify-between mt-3 mb-3'>
                <p className=' text-back-color2 font-[500] text-[23px] capitalize'>{name}</p>
                <BookmarkBorderIcon className=' pt-2'/>
            </div>
            <div className=' mb-4'>
                <PlaceOutlinedIcon className='text-primary-color6'/> <p className=' text-[#44474E] inline-block pl-1'>{location}</p>
            </div>
            <div>
                <p className=' pb-2'>{percent}% sold</p>
                <Progress value={percent}/>
                <div className=' mb-4 flex justify-between'>
                    <p className='text-primary-color6'>Target</p>
                    <p className='text-primary-color6'>Raised</p>
                </div>
                <div className=' mb-2 flex justify-between'>
                    <p>NGN {target}</p>
                    <p className=' text-primary-color'>NGN {raised}</p>
                </div>
            </div>


        </div>
    </div>
  )
}

export default InvestmentComponent
import React from 'react'
import Blog from '../../Assets/images/bb1.jpg'
import B from '../../Assets/images/bllog0.jpeg'
import R from '../../Assets/images/brecent.png'
import BlogComponent from './BlogComponent'
function BlogPost() {
  return (
    <div className='px-2 md:px-20  pt-32 '>
        <div>
        <h1 className=' text-[32px] mb-3 text-back-color2 pl-3'>Top posts</h1>
        <div className=' grid sm:grid-cols-2 gap-4'>

       
            <BlogComponent
                img={Blog}
                header='5 HABITS OF REAL ESTATE TYCOONS'
                date='JUN 12, 2023'
                link='https://zousellc.medium.com/5-habits-of-real-estate-tycoons-a3ddc61c66e4'
                sub='Real estate tycoons are individuals who have built massive wealth and fortunes by buying, selling, and managing real estate. These successful Individuals have developed certain habits that have contributed to their success.'
                headerColor=' text-[#1E222B]'
                dateColor=' text-[#808285]'
                subColor=' text-[#808285]'
            />
            <BlogComponent
                img={B}
                header='Revolutionizing Real Estate: How Our Proptech Solution is Transforming the Industry'
                date='JUN 14, 2023'
                link='https://zousellc.medium.com/revolutionizing-real-estate-how-our-proptech-solution-is-transforming-the-industry-d6f6caeeb3d7'
                sub='The real estate industry is undergoing a major transformation, thanks to the power of PropTech solutions. '
                headerColor=' text-[#1E222B]'
                dateColor=' text-[#808285]'
                subColor=' text-[#808285]'
            />
        </div>
        </div>
        {/* <div>
        <h1 className=' text-[32px] mb-3 text-back-color2 pl-3'>Recent posts</h1>
        <BlogComponent
            img={R}
            header='THE METAVERSE IS BROUGHT TO YOU BY…'
            date='JUN 7, 2022'
            sub='Will the taste of a cool crisp coke or an ice cold beer still taste as sweet in the metaverse? Let’s find out.'
            headerColor=' text-[#1E222B]'
                dateColor=' text-[#808285]'
                subColor=' text-[#808285]'
        />
         <BlogComponent
            img={R}
            header='THE METAVERSE IS BROUGHT TO YOU BY…'
            date='JUN 7, 2022'
            sub='Will the taste of a cool crisp coke or an ice cold beer still taste as sweet in the metaverse? Let’s find out.'
            headerColor=' text-[#1E222B]'
            dateColor=' text-[#808285]'
            subColor=' text-[#808285]'

        />
        </div> */}
    </div>
  )
}

export default BlogPost
import React from 'react'
import Fund from '../../Assets/images/nc.png'
function NeedFunding() {
  return (
    <div className=' bg-[#FAFAFA] py-8 md:py-16 mt-12 px-5 p-6 md:px-20 grid sm:grid-cols-2'>
    <div>
      <img src={Fund} alt='Funds'/>
    </div>
    <div className=' sm:flex sm:flex-col sm:justify-center'>
    <h1 className=' text-[40px] text-[#1E222B] font-[800] mb-4'>Need capital?</h1>
        <div className='wow animate__fadeInLeft animate__animated'>
            <div className=' '>
                <p>Traditional bank lending has become less and less available since the last financial crisis as regulations change and banks' preferences are tilted towards financing large corporations. Applying for a bank loan is a time-consuming and complex process with a highly unpredictable result. It is not uncommon to see good companies with excellent projects receive inferior credit offers or being rejected at all. 
                <br></br><br></br>
                Fast growing crowdfunding industry is changing the way companies used to fund their activities by bypassing financial intermediators and creating direct connections between investors and companies. 
                High-quality or high-potential projects are in high demand and can be funded in minutes after being published. 
                Crowdestate aims to provide you with a full capital stack - from senior loans to mezzanine debt to equity.</p>
            </div>
            
        </div>
    </div>
        
    </div>
  )
}

export default NeedFunding
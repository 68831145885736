import React, { useEffect } from 'react'
import FaqContent from '../../Components/Faq/FaqContent'
import TopSec from '../../Components/Faq/TopSec'
import Footer from '../../Components/Footer'
import NavBar from '../../Components/Navbar'
import WOW from 'wowjs';
import Download from '../../Components/Home/Download'
function Faq() {
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])
  return (
    <div>

        <NavBar/>
       
            <TopSec/>
            <div className=' px-2 md:px-20'>
            <FaqContent/>
           <Download/>
        </div>
        <Footer/>
    </div>
  )
}

export default Faq
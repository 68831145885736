import React, { useEffect } from 'react'
import Aboutuss from '../../Components/About/Aboutus'
import Mission from '../../Components/About/Mission'
import Patners from '../../Components/About/Patners'
import Topsec from '../../Components/About/Topsec'
import Footer from '../../Components/Footer'
import Download from '../../Components/Home/Download'
import NavBar from '../../Components/Navbar'
import WOW from 'wowjs';
function Aboutus() {
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])
  return (
    <div>
        <NavBar/>
        
            <Topsec/>
            <div className=' pt-20 px-6 md:px-20'>
            <Mission/>
            <Aboutuss/>
            <Patners/>
            
            <Download/>
        </div>
        <Footer/>
    </div>
  )
}

export default Aboutus
import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import P1 from '../../Assets/images/pat1.png'
import P2 from '../../Assets/images/pat2.png'
import P3 from '../../Assets/images/pat3.png'


class Patners extends Component{
    render(){
      const settings = {
       
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 5000,
        cssEase: "linear"
      };
      return(
        <div className=" p-6 md:p-16 mb-8 mt-24 md:mt-10">
          <h1 className=' text-back-color2 text-[40px] font-[800] text-center    mt-5 mb-10 '>Our partners</h1>
          <Slider {...settings}>
            <div className="container">
              <img alt='logo' className=' m-1' src={P1} />
            </div>
            <div className="container">
              <img alt='logo' className=' m-1'  src={P2}/>
            </div>
            
            <div className="container">
              <img alt='logo' className=' m-1' src={P3} />
            </div>
            <div className="container">
              <img alt='logo' className=' m-1' src={P2}/>
            </div>
            <div className="container">
              <img alt='logo' className=' m-1' src={P1} />
            </div>
            <div className="container">
              <img alt='logo' className=' m-1'  src={P3}/>
            </div>
          </Slider>
          <div className=' mt-5'>
          <Slider {...settings}>
            <div className="container">
              <img alt='logo' className=' m-1' src={P1} />
            </div>
            <div className="container">
              <img alt='logo' className=' m-1'  src={P2}/>
            </div>
            
            <div className="container">
              <img alt='logo' className=' m-1' src={P3} />
            </div>
            <div className="container">
              <img alt='logo' className=' m-1'  src={P1}/>
            </div>
            <div className="container">
              <img alt='logo' className=' m-1' src={P3} />
            </div>
            <div className="container">
              <img alt='logo' className=' m-1'  src={P2}/>
            </div>
          </Slider>
          </div>
        </div>
      );
    }
  }
  
  export default Patners;
import React from 'react'
import TwoCom from './TwoCom'
import MImage from '../../Assets/images/aas.png'
import Button from '../SelectValue/Button'
import List from '../../Assets/images/crownd.svg'
import Constant from '../../Constant'
function ListingPro() {
  return (
    <div className=' p-8 md:p-20 grid sm:grid-cols-2 gap-4'>
    <div className=' sm:hidden block'>
            <div>
                <img src={MImage} alt='alt'/>
            </div>
        </div>
        <div>
            <h1 className=' text-[24px] md:text-[48px] text-back-color2 mb-3 font-[800]'>List property on zouse</h1>
            <p className=' text-[14px] md:text-[18px] text-back-color2 mb-3 font-[400]'>Sign up as a business and get your properties seen buy thousands
of investors on our platform.</p>
            <div>
            <TwoCom
                name='Transparent'
            />
             <TwoCom
                name='minimal transaction fees'
            />
             <TwoCom
                name='Get buyers quickly'
            />
             <TwoCom
                name='NGN 5000 minimum investment'
            />
        </div>
        <div className=' mt-4'>
        <a href={Constant.BASE_LINK_URL + '/'} target='_blank' rel="noreferrer">
            <Button
                name='Get started'
            />
            </a>
        </div>
        </div>
       
        <div className=' hidden relative sm:block'>
            <div>
                <img src={MImage} alt='alt'/>
            </div>
            <div className='  bg-white flex gap-3 absolute bottom-5 sm:bottom-[200px] lg:bottom-[66px] right-0 shadow rounded-lg p-2 '>
                    <img src={List} alt='List'/> <p>List a property</p>
                   </div>
        </div>
    </div>
  )
}

export default ListingPro
import {BrowserRouter, Route,  Routes} from 'react-router-dom';
import Aboutus from './Pages/About';
import Blog from './Pages/Blog';
import Faq from './Pages/Faq';
import GetFunding from './Pages/Funding';
import Home from './Pages/Home';
import Invest from './Pages/Invest';
import Listing from './Pages/Listing';
import WOW from 'wowjs';
import { useEffect } from 'react';
import Scrolltotop from './Components/Scrolltotop';
import Contactus from './Pages/Contact';
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';

function App() {

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])
  
  return (
    <div >
      <BrowserRouter> 
      <Scrolltotop/>
      <Routes >
        <Route path='/' element={<Home />} />
        <Route path='/invest' element={<Invest/>} />
        <Route path='/terms' element={<Terms/>}/>
        <Route path='/privacy' element={<Privacy/>}/>
       <Route path='/funding' element={<GetFunding/>}/>
       <Route path='/blog' element={<Blog/>} />
       <Route path='/faq' element={<Faq/>} />
       <Route path='/aboutus' element={<Aboutus/>}/>
       <Route path='/listing' element={<Listing/>}/>
       <Route path='/contactus' element={<Contactus/>}/>
       <Route path='*' element={<Home />} />
      </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;

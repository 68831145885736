import React from 'react'
import Icon from '../../Assets/images/pp.svg'
function TwoCom({name}) {
  return (
    <div className=' flex py-3 gap-4'>
        <img src={Icon} alt='Icon'/>
        <p className=' text-[18px] font-[400] text-back-color2'>{name}</p>
    </div>
  )
}

export default TwoCom
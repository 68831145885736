import React from 'react'
import WhyComponent from '../Home/WhyComponent'

function Why() {
  return (
    <div className=' px-2 md:py-16 md:px-20 why-bg p-5 '>
        <div className=' mb-6'>
            <h1 className=' font-[800] text-[40px] text-white mb-2'>Why Zouse?</h1>
            <div className=' text-primary-color5 md:w-1/2 '>
            Trust is our currency. We are committed to the security of your money and the protection of your account.
            </div>
        </div>
        <div className=' grid lg:grid-cols-4 gap-2 md:grid-cols-3 grid-cols-1'>
            <WhyComponent
                header='Simple'
                sub='Finding funding and servicing the loan is easy. Just fill the electronic application on our website or send us an e-mail. Your project could be fully funded soon! Fill the application here'
            />
            <WhyComponent
                header='Fast'
                sub='Your project could be fully funded soon! Fill the application here Once we have approved your project, and opened it on our website, you will have access to thousands of investors to fund your project.'
            />
             <WhyComponent
                header='Transaparent'
                sub='You will at all times have a clear overview of our fees and the total cost of raising funds on Zouse platform.'
            />
             <WhyComponent
                header='Competent'
                sub=' Zouse’s extensive experience with real estate and crowdfunding projects enables us to evaluate your project and if needed, suggest improvements. Providing comprehensive info to our investors will make their investment decision quick and easy.'
            />
            
        </div>
    </div>
  )
}

export default Why
import React from 'react'
import Avater from '../../Assets/images/ii.png'
function RatingComponent({name, cont}) {
  return (
    <div className=' m-3 p-3  shadow-lg border border-gray-100 rounded-[72px]  relative   flex'>
        <div className=' flex flex-col justify-center '>
        <div>
        <img src={Avater} width='70' height='70'  alt='Avater'/>
        </div>
            
        </div>
        <div>
        <div>
        <p className=' text-[#5E5F5D] text-[16px] sm:text-[20px] font-[700]   px-4'>{name}</p>
        <p className=' text-[#5E5F5D] text-[12px] sm:text-[16px] font-[400]   px-4'>
           {cont}</p>
        </div>
        
        </div>
    </div>
  )
}

export default RatingComponent
import React from 'react'

function HowCountCom({num, header, sub, color}) {
  return (
    <div className=' flex my-3 gap-8 xl:w-[70%]'>
    <div className=' '>
    <div className=' inline-block   '>
            <p className={` ${color} border-[3px] bg-white p-2 border-[#1E222B] rounded-full w-30 h-30 text-[24px] inline-block font-[700]`}>{num}</p>
        </div>
    </div>
       
        <div>
            <h1 className=' font-[400] text-[16px] sm:text-[32px] mb-2'>{header}</h1>
            <p className=' font-[400] text-[14px] sm:text-[18px] mb-2'>{sub}</p>
        </div>
    </div>
  )
}

export default HowCountCom
import React, { useEffect, useState } from 'react'

import Line from '../../Assets/images/Line.png'
import Progress from './Progress'
import Button from '../SelectValue/Button'
import Button2 from '../SelectValue/Button2'
import Constant from '../../Constant'
function PropertyComponent({name,roi, ppu, daysleft, invested, leftinvest, perc, type, img }) {
    const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    const targetDate = new Date(daysleft);
    const currentDate = new Date();

    const timeDifference = targetDate.getTime() - currentDate.getTime();
    const days = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    setDaysLeft(days);
  }, [daysleft]);
  return (
    <div className=' wow  animate__animated animate__zoomIn grid md:grid-cols-2 my-5 gap-4 bg-white'>
        <div>
            <img src={img} alt='house' className=' w-full h-[350px]'/>
        </div>
        <div className=' bg-white p-4 pr-8  md:pr-24'>
            <p className=' text-primary-color5 mb-5 capitalize'>{type}</p>
            <p className=' text-[32px] text-back-color2  capitalize mb-5'>{name}</p>
            <div className=' flex justify-between gap-2 mb-7'>
                <div>
                    <p className=' text-primary-black text-[20px] mb-3'>{roi}</p>
                    <p>Active investors</p>
                </div>
                <div>
                <img src={Line} alt='Lines'/>
                </div>
                
                <div>
                    <p className=' text-primary-black text-[20px] mb-3'>{ppu}</p>
                    <p>Price per unit</p>
                </div>
                <div>
                <img src={Line} alt='Lines'/>
                </div>
                <div>
                    <p className=' text-primary-black text-[20px] mb-3'>{daysLeft} days</p>
                    <p>Left to Invest</p>
                </div>
            </div>
            <Progress value={perc}/>
            <div className=' flex justify-between mt-5'>
            <div>
                <p className=' text-primary-color text-[24px] inline-block'>₦{invested}</p>  <p className=' text-[#808285] text-[18px] inline-block'>invested</p>
            </div>
            <div>
                <p className=' text-back-color2 text-[24px] inline-block'>₦{leftinvest}</p>  <p className=' text-[#808285] text-[18px] inline-block'>left to invest</p>
            </div>
            </div>
            <div className='flex gap-6 mt-6'>
            <a href={Constant.BASE_LINK_URL + '/signup'}  target='_blank' rel='noreferrer'>
            <Button
                    name='Create account'
                />
            </a>
                <a href={Constant.BASE_LINK_URL + '/'} target='_blank' rel='noreferrer'>
                <Button2
                    name='Login'
                />
                </a>
               
            </div>
        </div>
    </div>
  )
}

export default PropertyComponent
import React from 'react'
import Button2 from '../SelectValue/Button2'
import OfferCom from './OfferCom'
function Offers() {
  return (
    <div className=' grid md:grid-cols-3 gap-6 mb-5 mt-14'>
    <div className=' p-6 wow animate__zoomIn animate__animated md:px-10 rounded-lg fund-bg'>
    <div className=' '>
        <h1 className=' text-primary-black font-[800] text-[32px] mb-3'>Fund your Project</h1>
        <OfferCom
            name='Speedy fundraising process'
        />
         <OfferCom
            name='NGN 5000 minimum funding amount'
        />
         <OfferCom
            name='Interest rates starting from 10%'
        />
         <OfferCom
            name='Flexible repayment options'
        />
    </div>
    <div className=' mt-6 mb-6'>
        
        <Button2
            name='Get funding'
        />
    </div>
    </div>
    <div className=' p-6 wow animate__zoomIn animate__animated md:px-10 rounded-lg invest-bg'>
    <div className=' '>
        <h1 className=' text-primary-black font-[800] text-[32px] mb-3'>Invest and Trade</h1>
        <OfferCom
            name='Minimal investment fees'
        />
         <OfferCom
            name='Prevetted Investments only'
        />
         <OfferCom
            name='Private and business accounts'
        />
         <OfferCom
            name='NGN 5000 minimum investment'
        />
    </div>
    <div className=' mt-6 mb-6'>
        
        <Button2
            name='Start investing'
        />
    </div>
    </div>
    <div className=' wow animate__zoomIn animate__animated p-6 md:px-10 rounded-lg list-bg'>
    <div className=' '>
        <h1 className=' text-primary-black font-[800] text-[32px] mb-3'>List property</h1>
        <OfferCom
            name=' Minimal investment fees'
        />
         <OfferCom
            name='Prevetted Investments only'
        />
         <OfferCom
            name='Private and business accounts'
        />
         <OfferCom
            name='NGN 5000 minimum investment'
        />
    </div>
    <div className=' mt-6 mb-6'>
        
        <Button2
            name='Sign up as business'
        />
    </div>
    </div>
    </div>
  )
}

export default Offers
import React from 'react'
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import Constant from '../../Constant';
function Start() {
  return (
    <div className=' mt-10 mb-3'>
        <h1 className=' text-[24px] font-[800] text-back-color2 text-center mb-3'>Start now</h1>
        <p className=' text-[18px] text-primary-color6 text-center'>Gain access to thousands of investors to fund your project quick and easy</p>
        <div className=' mt-3 flex justify-center'>
        <a href={Constant.BASE_LINK_URL + '/'} target='_blank' rel="noreferrer">
        <button 
      className=' bg-primary-color   px-4 py-2 border-none rounded-lg text-white'
      type='submit'  >Start now  <EastOutlinedIcon className=' pl-1'/></button> 
      </a>
        </div>
    </div>
  )
}

export default Start
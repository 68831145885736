import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RatingComponent from './RatingComponent';

function SampleNextArrow(props) {
  return (
    <div 
    />
  );
}

function SamplePrevArrow(props) {
  return (
    <div
    />
  );
}

class RatingSlider extends Component{
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
    render(){
      const settings = {
       
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 5000,
        cssEase: "linear",
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: true,
                speed: 5000,
                autoplaySpeed: 5000,
                cssEase: "linear",
              }
            },
            {
              breakpoint: 600,
              settings: {
                infinite: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplay: true,
                speed: 5000,
                autoplaySpeed: 5000,
                cssEase: "linear",
              }
            },
            {
              breakpoint: 480,
              settings: {
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                speed: 5000,
                autoplaySpeed: 5000,
                cssEase: "linear",
              }
            }
          ]
      };
      return(
        <div className=" mt-10">
        
         <div className=' hidden sm:block'>
          <Slider {...settings}>
            <div className="container">
            <RatingComponent
              name='Usman Halimat'
              cont='My spouse and I just relocated from Osun State to Lagos State. We selected the Zouse Real 
             Estate Team based on reviews we saw online and we were so lucky to have chosen them'
            />
            </div>
            <div className="container">
            <RatingComponent
              name='Owolabi Israel'
              cont=' If you are looking for a property Tech Company to sell your house, Zouse should be your first choice. They gave us many helpful suggestions on how to update our home wonderful to deal with.'
            />
            </div>
            <div className="container">
            <RatingComponent
              name='Raji Uthman'
              cont='Zouse team is a joy to work with! The breath of his local knowledge of Kwara, ME and its 
environs along with a thorough real estate expertise was invaluable to us Kwara novices.'
            />
            </div>
            <div className="container">
            <RatingComponent 
              name='David Monday'
              cont="I have to say that Zouse is by far the BEST realtor we've ever worked with, their professionalism, personality, attention to detail, responsiveness and ability to close the deal was Outstanding"
            />
            </div>
            <div className="container">
            <RatingComponent
              name='Akinbode Ezekiel'
              cont=' We had a very positive experience with Zouse - both in the sale of our previous home, and 
the search for & purchase of our new home. '
            />
            </div>
          </Slider>
          
          <Slider {...settings} className=' mt-3'>
            <div className="container">
            <RatingComponent 
              name='David Monday'
              cont="I have to say that Zouse is by far the BEST realtor we've ever worked with, their professionalism, personality, attention to detail, responsiveness and ability to close the deal was Outstanding"
            />
            </div>
            <div className="container">
            <RatingComponent
              name='Raji Uthman'
              cont='Zouse team is a joy to work with! The breath of his local knowledge of Kwara, ME and its 
environs along with a thorough real estate expertise was invaluable to us Kwara novices.'
            />
            </div>
            <div className="container">
            <RatingComponent
              name='Owolabi Israel'
              cont=' If you are looking for a property Tech Company to sell your house, Zouse should be your first choice. They gave us many helpful suggestions on how to update our home wonderful to deal with.'
            />
            </div>
            <div className="container">
            <RatingComponent
              name='Usman Halimat'
              cont='My spouse and I just relocated from Osun State to Lagos State. We selected the Zouse Real 
             Estate Team based on reviews we saw online and we were so lucky to have chosen them'
            />
            </div>
            
          </Slider>
          </div>
        <div className=' block sm:hidden'>
        <RatingComponent 
              name='David Monday'
              cont="I have to say that Zouse is by far the BEST realtor we've ever worked with, their professionalism, personality, attention to detail, responsiveness and ability to close the deal was Outstanding"
            />
         <RatingComponent
              name='Raji Uthman'
              cont='Zouse team is a joy to work with! The breath of his local knowledge of Kwara, ME and its 
environs along with a thorough real estate expertise was invaluable to us Kwara novices.'
            />
        <RatingComponent
              name='Owolabi Israel'
              cont=' If you are looking for a property Tech Company to sell your house, Zouse should be your first choice. They gave us many helpful suggestions on how to update our home wonderful to deal with.'
            />
        <RatingComponent
              name='Usman Halimat'
              cont='My spouse and I just relocated from Osun State to Lagos State. We selected the Zouse Real 
             Estate Team based on reviews we saw online and we were so lucky to have chosen them'
            />
        </div>
        </div>
      );
    }
  }
  
  export default RatingSlider;
import React from 'react'

function Topsec() {
  return (
    <div className=' md:mb-6 px-2  pt-20 sm:pt-32 md:px-0 about-bg'>
        <div className=' wow  animate__animated animate__fadeInLeft  md:px-10   '>
            <div className='p-16 sm:p-32  grid h-full  place-items-center   '>
            <div>
                <h1 className='md:text-[18px] text-white text-center mb-3 font-[800]'>About us</h1>
                    <h1 className=' text-[20px] md:text-[40px] text-white text-center mb-3 font-[800]'>Providing secure access to real estate investments and savings products for our users by digitizing investment management.</h1>
                    {/* <p className=' text-[#1A1A1B] text-center'>We are fostering a culture of saving and investing among the expanding number of Nigerian middle-class and millennials.</p> */}
            </div>
            </div>
            
        </div>
    </div>
  )
}

export default Topsec

import React, { Component } from "react";
import TopCom1 from './TopCom1'
export default class PreviousNextMethods extends Component {
  render() {
    
    return (
      <div>
        <div className=' mx-5'>
       <TopCom1/>
        </div>
       
      </div>
    );
  }
}
               


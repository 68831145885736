import React, { useState } from 'react'
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import WifiCalling3OutlinedIcon from '@mui/icons-material/WifiCalling3Outlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import Inputtt from '../SelectValue/Inputtt';
import Textarea from '../SelectValue/TextArea';
import Button from '../SelectValue/Button';
import getServices from '../../Services/getServices';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../SelectValue/Loading';
function ContactCon() {
    const [fName, setFirstName] = useState('')
    const [lName, setLastName] = useState('')
    const [message, setMessage] = useState('')
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const sendMail = (e) =>{
            e.preventDefault()
            setLoading(true)
            getServices.sendEmail(email,fName,lName,message).then(
                (response) => {
                    setLoading(false)
                    if(response.data.status === "2000"){
                     
                      toast.success('Message sent successfully. Thanks you')
                      setFirstName('')
                      setEmail('')
                      setLastName('')
                      setMessage('')
                    }else{
                        toast.error('Error Occured try again later',)
                    }
                   
                    console.log(response.data.data)
                    
                  },
                  (error) => {
                    setLoading(false)
                    toast.error('Error Occured try again later',)
                  }
            )
    }
  return (
    <div>
    <ToastContainer/>
    <Loading
        open={loading}
    />
    <div className=' bg-[#002615] p-10 sm:p-20 grid sm:grid-cols-2 gap-4 '>

        <div>
            <p className=' font-[400] text-[16px] text-white pb-4'>Contact us</p>
            <h1 className=' font-[500] text-[24px] pb-4 text-white'>Chat with our friendly team</h1>
            <p className=' font-[400] text-[16px] pb-4 text-white'>We’d love to hear from you. Please fill out this form or shoot us an email.</p>
            <div className=' py-3 grid  grid-cols-2 gap-4'>
                <div>
                    <MarkEmailUnreadOutlinedIcon className=' text-white'/>
                    <p className=' py-2 font-[500] text-white text-[16px]'>Email</p>
                    <p  className='py-2 font-[400] text-white/70 text-[16px]'>Our friendly team is here to help</p>
                    <p className=' py-2 font-[500] text-white text-[16px]'>Hello@zouse.com</p>
                </div>
                <div>
                    <WifiCalling3OutlinedIcon className=' text-white'/>
                    <p className=' py-2 font-[500] text-white text-[16px]'>Phone</p>
                    <p  className='py-2 font-[400] text-white/70 text-[16px]'>Mon-Fri from 9am to 4pm</p>
                    <p className=' py-2 font-[500] text-white text-[16px]'>+234 4849 999 222</p>
                </div>
            </div>
            <div className=' py-3 grid  grid-cols-2 gap-4'>
                <div>
                    <PlaceOutlinedIcon className=' text-white'/>
                    <p className=' py-2 font-[500] text-white text-[16px]'>Lagos office</p>
                    <p  className='py-2 font-[400] text-white/70 text-[16px]'>Come say hello to us</p>
                    <p className=' py-2 font-[500] text-white text-[16px]'>No 91 Ogodu road, Ojota Lagos.</p>
                </div>
                <div>
                    <PlaceOutlinedIcon className=' text-white'/>
                    <p className=' py-2 font-[500] text-white text-[16px]'>Kwara office</p>
                    <p  className='py-2 font-[400] text-white/70'>Come say hello to us</p>
                    <p className=' py-2 font-[500] text-white text-[16px]'>121 Olanrewaju Building Muritala way  Post Office Road, Ilorin, Kwara State, Nigeria</p>
                </div>
            </div>
        </div>
        <div className=' bg-white rounded-xl p-6'>
        <form onSubmit={sendMail}>
        <div className=' grid grid-cols-2 gap-5'>
            <div>
            <Inputtt
                label='Firstname'
                type='text'
                value={fName}
                onChange={setFirstName}
              />  
            </div>
            <div>
            <Inputtt
                label='Lastname'
                type='text'
                value={lName}
                onChange={setLastName}
              />  
            </div>
            </div>
            <div>
            <Inputtt
                label='Email'
                type='email'
                value={email}
                onChange={setEmail}
              />  
            </div>
            <div>
                <Textarea
                    label='Message'
                    onChange={setMessage}
                    value={message}
                />
            </div>
            <div className=' grid grid-cols-1 mt-3'>
                <Button
                    name='Send message'
                />
            </div>
        </form>
            
        </div>
    </div>
    </div>
  )
}

export default ContactCon
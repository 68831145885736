import React, { useEffect } from 'react'
import Footer from '../../Components/Footer'
import Start from '../../Components/Funding/Start'
import Download from '../../Components/Home/Download'
import TopListing from '../../Components/Listing/TopListing'
import TopSec from '../../Components/Listing/TopSec'
import NavBar from '../../Components/Navbar'
import WOW from 'wowjs';
function Listing() {
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])
  return (
    <div>
        <NavBar/>
        <div className=' px-2 md:px-20'>
        <TopSec/>
        
        </div>
        <TopListing/>
        <div className=' px-2 md:px-20'>
        <Download/>
        <Start/>
        </div>
        
        <Footer/>
    </div>
  )
}

export default Listing
import React, { useEffect, useState } from 'react'
import getServices from '../../Services/getServices';
import ListingCom from './ListingCom'
import Listing from '../../Assets/images/listing.png'
import ListingCom2 from './ListingCom2';
function TopListing() {
  const [landListing, setLandingListing] = useState([])
  const [propListing, setProListing] = useState([])

  let counter = 0;
   const getData = () =>{
          getServices.getAllListing()
        .then(response => {
          // Handle the successful response
          setLandingListing(response.data.investmentPlans.reverse())
          setProListing(response.data.propertyPlan.reverse())
          console.log(response.data);
        })
        .catch(error => {
          // Handle the error
          console.error(error);
        });
   }

   useEffect(() => {
    getData()
   }, [])
   const filteredDataL = landListing.filter(item => item.propertyStatus === 'active');
   const filteredDataP = propListing.filter(item => item.propertyStatus === 'active');
  return (
    <div className=' mt-10 bg-[#FAFAFA]'>
    <div className=' px-2 py-4 md:px-20'>
    {/* <h1 className=' text-back-color2 text-[48px] font-extrabold py-5  wow  animate__animated animate__fadeInLeft'>Top listings</h1> */}
    <h5  className=' text-back-color2 text-[32px] font-extrabold py-5  wow  animate__animated animate__fadeInLeft'>Land Listing</h5>
    <div className=' grid md:grid-cols-3 gap-5 '>
    {filteredDataL.map((items, index) =>{
            if (counter < 3) {
          counter++;
          return  <div key={index}>
                 <ListingCom
                  name={items.title}
                  img={items.imageUrl[0] ?? Listing}
                  count={items.imageUrl.lenght ?? 1 }
                  desc={items.description}
                  start={items.createdDate?.slice(0,10) ?? 'No Date'}
                  price={items.price}
                  location={items.locality}
                  area={items.coveredArea}
                  plot={items.totalPlots}
                 />
             </div>
            }
             return null
           })}
           </div>
           {filteredDataL  === 0 && <p className=' text-center text-red-700 text-2xl'> No active Land Listing currently</p>}
           <h5 className=' text-back-color2 text-[32px] font-extrabold py-5  wow  animate__animated animate__fadeInLeft'>Property Listing</h5>
           <div className=' grid md:grid-cols-3 gap-5 '>
            {filteredDataP.map((items, index) =>{
            if (counter < 3) {
              counter++;
              return  <div key={index}>
                    <ListingCom2
                       name={items.title}
                  img={items.imageUrl[0] ?? Listing}
                  count={items.imageUrl.lenght ?? 1 }
                  desc={items.description}
                  start={items.createdDate?.slice(0,10) ?? 'No Date'}
                  price={items.price}
                  location={items.locality}
                  area={items.coveredArea}
                  plot={items.totalPlots}
                    />
                </div>
                }
             return null
           })}
           </div>
           {filteredDataP  === 0 && <p className=' text-center text-red-700 text-2xl'> No active Property Listing currently</p>}
    </div>
   
    </div>
    
  )
}

export default TopListing
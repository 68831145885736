import React from 'react'
import Topsec from '../../Components/Contactus/Topsec'
import Footer from '../../Components/Footer'
import NavBar from '../../Components/Navbar'
import Contactus from '../../Components/Contactus/Contactus'
import ContactCon from '../../Components/Contactus/ContactCon'

function Contactuss() {
  return (
    <div className='   '>
     <NavBar/>
    <Topsec/>
    <ContactCon/>
    <Contactus/>
    <Footer/>
    </div>
  )
}

export default Contactuss
import React from 'react'

function Content() {
  return (
    <div className=' mt-10 px-10 sm:px-20'>
        <div>
            <p className=' text-[20px] text-[#1A1A1B] font-[500]'>This Privacy Policy governs the manner in which Zouse collects, uses, maintains and discloses information collected from users (each, a “User”) of the Zouse. This privacy policy applies to the Site and all products and services offered by the ZOUSE,we show you how we take your privacy seerious wihin our business.
                <br></br> <br></br>
                Our Privacy Policy, available on our website, explains how we collect, use, and disclose information about you. By using the Service, you consent to our collection, use, and disclosure of your information as described in the Privacy Policy.</p>
        </div>
        <div className=' mt-8'>
        <p className="  text-[#00BC67]  sm:text-[20px] font-bold leading-loose tracking-wide">What does the privacy policy cover?</p>
        <p className=' mt-4 text-back-color2 sm:text-[20px]'>This Privacy Policy covers our collection, use and disclosure of information about identifiable individuals and information which can be used to identify an individual (“Personal Information”). Personal Information may be collected about users and visitors to the Website, as well as our customers or affiliates and their end users who use the Services.</p>
       <p className=' mt-4 text-back-color2 sm:text-[20px]'>This Privacy Policy covers the activities of ZOUSE. This Privacy Policy does not apply to the practices of property that we do not own or control, including our customers that sell homes who may use the Services on their own websites to collect Personal Information. Third parties, including but not limited to our home seller or other affiliated companies, are responsible for ensuring that they have obtained the necessary authorizations and consents for any Personal Information you make available to us for use in accordance with this Privacy Policy.</p>
        </div>
        <div className=' my-8'>
        <p className="  text-[#00BC67]  sm:text-[20px] font-bold leading-loose tracking-wide">Personal identification information</p>
            <p className='mt-3 text-back-color2 sm:text-[20px]'>We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, subscribe to the newsletter, respond to a survey, fill out a form, and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address,phone number, mailing address,. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.</p>
        </div>
        <div className=' mb-8'>
            <p className="  text-[#00BC67]  sm:text-[20px] font-bold leading-loose tracking-wide">Use of Personal Information. Zouse uses the Account Information and Services Information to:</p>
            <div>
                <ul className="list-disc list-inside">
                    <li className='text-back-color2 sm:text-[20px] '>Respond to comments, questions, and requests and provide customer service and support.</li>
                    <li className='text-back-color2 sm:text-[20px] '>communicate with you about services, features, surveys, newsletters, offers, promotions, contests and events, and provide other news or information about us and our select partners;</li>
                    <li className=' text-back-color2 sm:text-[20px]'>To improve our Site.</li>
                    <li className=' text-back-color2 sm:text-[20px]'>To run a promotion, contest, survey or other Site feature</li>
                    <li className=' text-back-color2 sm:text-[20px]'>To send periodic emails.</li>
                    <li className=' text-back-color2 sm:text-[20px]'>investigate and prevent fraudulent transactions, unauthorized access to the Services, and other illegal activities;</li>
                    <li className=' text-back-color2 sm:text-[20px]'>To improve customer services</li>
                </ul>
                <p className='mt-3 text-back-color2 sm:text-[20px]'>For other purposes which we will notify you about and seek your consent.</p>
            </div>
        </div>
        <div className=' mb-8'>
        <p className="  text-[#00BC67]  sm:text-[20px] font-bold leading-loose tracking-wide">How we protect your information</p>
        <div>
            <p className='mt-3 text-back-color2 sm:text-[20px]'>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.</p>
        </div>
        </div>
        <div className=' sm:mb-20'>
        <p className="  text-[#00BC67]  sm:text-[20px] font-bold leading-loose tracking-wide">Sharing your personal information</p>
        <div>
            <p className='mt-3 text-back-color2 sm:text-[20px]'>We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above. We may use third party service providers to help us operate our business and the Site or administer activities on our behalf, such as sending out newsletters or surveys. We may share your information with these third parties for those limited purposes provided that you have given us your permission.</p>
        </div>
        </div>
    </div>
  )
}

export default Content
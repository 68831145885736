import React from 'react'
import Icon from '../../Assets/images/rf.png'
import Constant from '../../Constant'
import Button from '../SelectValue/Button'
import TwoCom from './TwoCom'
function RaiseFund() {
  return (
    <div className=" my-16 grid px-5 md:px-20 sm:grid-cols-2 gap-5">
       <div>
        <div>
            <img src={Icon} alt='Raise fund' />
        </div>
       </div> 
       <div className=' flex justify-center flex-col'>
            <div>
            <h1 className=' text-[24px] md:text-[48px] text-back-color2 mb-3 font-[800]'>Raise funds on zouse</h1>
            <p className=' text-[14px] md:text-[18px] text-back-color2 mb-3 font-[400]'>Raise funds for your projects on zouse through our crowdfunding
                investment scheme.</p>
            <div>
            <TwoCom
                name='Speedy fundraising process'
            />
             <TwoCom
                name='NGN 5000 minimum funding amount'
            />
             <TwoCom
                name='Interest rates starting from 10%'
            />
             <TwoCom
                name='Flexible repayment options'
            />
        </div>
        <div className=' mt-4'>
        <a href={Constant.BASE_LINK_URL + '/'} target='_blank' rel="noreferrer">
            <Button
                name='Get started'
            />
            </a>
        </div>
            </div>
       </div>
    </div>
  )
}

export default RaiseFund
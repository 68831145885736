import React, { useEffect, useState } from 'react'
import getServices from '../../Services/getServices';
import PropertyComponent from './PropertyComponent'
import House from '../../Assets/images/house2.png'
function NewInvestment() {
  const [data, setData] = useState([])
  let counter = 0;
   const getData = () =>{
          getServices.getAllInvestments()
        .then(response => {
          // Handle the successful response
          setData(response.data.data.reverse())
          console.log(response.data);
        })
        .catch(error => {
          // Handle the error
          console.error(error);
        });
   }
   useEffect(() => {
    getData()
   }, [])
   const filteredData = data.filter(item => item.projectStatus === 'active');
  return (
    <div className=' px-2 md:px-20 bg-[#FAFAFA] p-4 my-10 '>
            <h1 className=' text-[16px] md:text-[40px] font-[800] wow  animate__animated animate__fadeInLeft text-[#1E222B]'>New Investment opportunities</h1>
            <div className=' mt-6'>
            {filteredData.map((items, index) =>{
            if (counter < 3) {
          counter++;
          return  <div key={index}>
                  <PropertyComponent
                    name={items.projectName}
                    type={items.projectType}
                    ppu={items.pricePerUnit}
                    roi={items.totalInvestors}
                    perc={items.percentSold}
                    invested={items.pricePerUnit * items.unitSold}
                    leftinvest={items.pricePerUnit * items.unitAvailable}
                    img={items.imageUrl[0] ?? House}
                    daysleft={items.endDate ?? '2024-06-08T16:00:00.000+00:00'}
                  />
             </div>
            }
             return null
           })}
                
                
            </div>
            {filteredData.length === 0 && <p className=' text-center text-red-700 text-2xl'> No active investment currently</p>}
    </div>
  )
}

export default NewInvestment
import * as React from 'react';
import FaqCom from './FaqCom';


function FaqContent() {
  return (
    <div className=' mt-10 wow  animate__animated animate__fadeInLeft'>
     <h1 className=' text-[24px] sm:text-[30px] font-[400] text-back-color2 mb-4'>Selected answered questions</h1>
     <div className=' grid md:grid-cols-3 sm:grid-cols-2 gap-4'>
        <FaqCom
          header='How can I register as an investor/property owner/developer on Zouse?'
          content='To register, simply visit our website/app and follow the registration process, 
                providing the necessary information and completing the required steps based on 
                your role (investor/property owner/developer).'
        />
         <FaqCom
          header='How are the investment opportunities curated and selected?'
          content='We carefully curate and select investment opportunities based on various factors 
such as project viability, financial projections, risk assessments, and alignment with 
investor preferences. This ensures that we present high-quality and diverse 
investment options to our registered investors.'
        />
         <FaqCom
          header='How secure are the transactions and fund transfers?'
          content='We prioritize the security of transactions and fund transfers. We utilize robust 
encryption protocols and work with trusted payment gateways to ensure that all 
financial transactions are secure and protected.'
        />
        <FaqCom
          header='Can I invest in multiple projects simultaneously?'
          content='Yes, as an investor, you have the flexibility to invest in multiple projects 
simultaneously. Our platform allows you to diversify your investment portfolio by 
selecting and investing in various opportunities that match your investment goals 
and risk appetite.'
        />
         <FaqCom
          header='How do I stay updated on the progress of my investments?'
          content="We provide regular updates on the progress of the projects you've invested in. 
You'll receive notifications and access to project reports, including key milestones, 
financial performance, and any relevant updates, ensuring that you stay informed 
throughout the investment lifecycle."
        />
         <FaqCom
          header='What are the potential returns on investments?'
          content="The potential returns on investments can vary based on the specific project and its
                performance. Returns may include rental income, capital appreciation, or a share of 
                the project's profits. Detailed information about expected returns and financial 
                projections are provided within each investment opportunity listing." />
        <FaqCom
          header='How does the platform ensure transparency and communication between 
investors and property owners/developers?'
          content='Our platform emphasizes transparency and facilitates clear communication 
between investors and property owners/developers. We provide a secure messaging
system and channels for all parties to interact, ask questions, and exchange 
information regarding the investment opportunity.'
        />
         <FaqCom
          header='Are there any fees or charges associated with using Zouse?'
          content='We may have a fee structure in place for certain services or transactions on the 
platform. However, any applicable fees or charges will be transparently 
communicated to you during the registration process or before you proceed with a 
specific action, ensuring full visibility of any associated costs.'
        />
         <FaqCom
          header='What if I change my mind or want to exit an investment early?'
          content="Depending on the specific investment and terms agreed upon, there may be 
options for exiting an investment early. It's important to carefully review the 
investment agreement and terms to understand any provisions related to 
premature exits or liquidity options."
        />
        <FaqCom
          header='How can I get support or assistance if I have further questions or issues?'
          content="We have a dedicated support team available to assist you with any questions or 
issues you may encounter. You can reach out to us through our customer support 
channels, which may include email, phone, or WhatsApp chat, and we'll be happy to
provide the necessary assistance."
        />
     </div>
    </div>
  )
}

export default FaqContent
import React from 'react'
import Phone from '../../Assets/images/homeI.png'
import Apple from '../../Assets/images/applee.svg'
import Google from '../../Assets/images/googleq.svg'
import Button from '../SelectValue/Button'
import List from '../../Assets/images/list.svg'
import Crownd from '../../Assets/images/crownd.svg'
import Constant from '../../Constant'
function TopCom1() {
  return (
    <div>
        <div  className=' pt-20  px-2 md:px-0  w-full'>
        <div className='   grid sm:grid-cols-2 gap-4'>
            <div className=' wow  animate__animated animate__fadeInLeft md:flex-col  flex justify-center   pt-6 '>
            <div>
                
                <div className=' relative pl-2 pt-3 '>
                   <h1 className=' font-[800 text-[24px] text-primary-black text-center sm:text-start sm:text-[48px]'>Welcome to the future of <span className=' text-primary-color'>Real estate</span>  investing.</h1>
                </div>
                <div className=' mt-6 '>
                    <p className=' text-[14px] text-center sm:text-start md:text-[18px] font-[400]'>Invest in income generating properties with zouse - The most transparent real estate crowdfunding platform with carefully selected and pre-vetted real estate investment opportunities in Nigeria.</p>
                </div>
                <div className=' mt-10 gap-4 flex justify-center sm:justify-start'>
                    <div className=' '>
                    <a href={Constant.BASE_LINK_URL + '/'} target='_blank' rel="noreferrer">
                    <Button
                        name='Get started'
                       />
                    </a>
                      
                    </div>
                    
                   
                </div>
                <div className=' mt-10 gap-4 hidden sm:flex'>
                    <div>
                        <img src={Apple} alt='Download on apple'/>
                    </div>
                    <div>
                    <img src={Google} alt='Download on google'/>
                    </div>
                </div>
            </div>
            </div>
            <div className=' md:pt-[52px] relative flex justify-center '>
                    <div>
                    <img src={Phone} className=' wow animate__zoomInUp animate__animated ' alt='Phone'/>
                    </div>
                   <div className='  bg-white flex gap-3 absolute top-[66px] left-0 shadow rounded-lg p-2 '>
                    <img src={Crownd} alt='Crownd'/> <p>Crowdfunding</p>
                   </div>
                   <div className='  bg-white flex gap-3 absolute bottom-5 sm:bottom-[200px] lg:bottom-[66px] right-0 shadow rounded-lg p-2 '>
                    <img src={List} alt='List'/> <p>List a property</p>
                   </div>
                    {/* <img src={Phones} className='wow animate__zoomInUp animate__animated md:hidden mt-3' alt='Phone'/> */}
            </div>
            <div className=' mt-10 gap-4 sm:hidden flex'>
                    <div>
                        <img src={Apple} alt='Download on apple'/>
                    </div>
                    <div>
                    <img src={Google} alt='Download on google'/>
                    </div>
                </div>
        </div>
        </div>
    </div>
  )
}

export default TopCom1
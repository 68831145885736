import React from 'react'
import BlogComponent from './BlogComponent'
import M1 from '../../Assets/images/m1.png'
import M2 from '../../Assets/images/m2.png'
import M3 from '../../Assets/images/m3.png'
function Money() {
  return (
    <div className='mt-10 py-5 md:py-10 px-2 md:px-20 bg-[#FAFAFA] '>
          <h1 className=' text-[40px] font-[800] text-back-color2 pl-3'>How we help you make money</h1>
            <div className=' grid md:grid-cols-3 gap-4'>
                <BlogComponent
                    img={M1}
                    header='THE NOTION OFTHE ORIGINAL: IN CONVERSATION WITH FILMMAKER WIM WENDERS'
                    date='JUN 7, 2022'
                    sub='Will the taste of a cool crisp coke or an ice cold beer still taste as sweet in the metaverse? Let’s find out.'
                    headerColor=' text-[#1E222B]'
                    dateColor=' text-[#808285]'
                    subColor=' text-[#808285]'

                />
                 <BlogComponent
                    img={M2}
                    header='THE NOTION OFTHE ORIGINAL: IN CONVERSATION WITH FILMMAKER WIM WENDERS'
                    date='JUN 7, 2022'
                    sub='Will the taste of a cool crisp coke or an ice cold beer still taste as sweet in the metaverse? Let’s find out.'
                    headerColor=' text-[#1E222B]'
                    dateColor=' text-[#808285]'
                    subColor=' text-[#808285]'

                />
                 <BlogComponent
                    img={M3}
                    header='THE NOTION OFTHE ORIGINAL: IN CONVERSATION WITH FILMMAKER WIM WENDERS'
                    date='JUN 7, 2022'
                    sub='Will the taste of a cool crisp coke or an ice cold beer still taste as sweet in the metaverse? Let’s find out.'
                    headerColor=' text-[#1E222B]'
                    dateColor=' text-[#808285]'
                    subColor=' text-[#808285]'

                />
            </div>
    </div>
  )
}

export default Money
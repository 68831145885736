import React from 'react'
import Down from '../../Assets/images/applee.svg'
import Google from '../../Assets/images/googleq.svg'
import List from '../../Assets/images/list.svg'
import Crownd from '../../Assets/images/crownd.svg'
import Phone from '../../Assets/images/homeI.png'
function Download() {
  return (
    <div className=' mt-7 px-2 md:px-0   w-full'>
        <div className=' px-5  md:px-10  grid sm:grid-cols-2 gap-4'>
            <div className='wow  pb-3 animate__animated animate__fadeInLeft md:flex-col flex justify-center   pt-6 '>
            <div className=' md:px-10'>
               
                <p className=' text-[#002615] text-center md:text-start text-[14px] md:text-[18px]'> Download the Zouse App</p>
                <div className=' mt-6 '>
                    <h1 className=' text-[18px] lg:text-[40px] text-center md:text-start font-[800]'>Join our 10,000+ users investing and setting long term goals!</h1>
                    </div>
                 <div className=' mt-10'>
                    <div className=' gap-4   sm:flex '>
                    <img src={Google} className=' mb-3 sm:mb-0 mx-auto sm:mx-0 '  alt='Google' />
                    <img src={Down} className='sm:mx-0 mx-auto ' alt='Download' />
                  
                    </div>
                   
                </div>
                
            </div>
            </div>
            <div className=' md:pt-[52px] relative flex justify-center '>
                    <div>
                    <img src={Phone} className=' wow animate__zoomInUp animate__animated ' alt='Phone'/>
                    </div>
                   <div className='  bg-white flex gap-3 absolute top-[66px] left-0 shadow rounded-lg p-2 '>
                    <img src={Crownd} alt='Crownd'/> <p>Crowdfunding</p>
                   </div>
                   <div className='  bg-white flex gap-3 absolute bottom-5 sm:bottom-[200px] lg:bottom-[66px] right-0 shadow rounded-lg p-2 '>
                    <img src={List} alt='List'/> <p>List a property</p>
                   </div>
                    {/* <img src={Phones} className='wow animate__zoomInUp animate__animated md:hidden mt-3' alt='Phone'/> */}
            </div>
        </div>
    </div>
  )
}

export default Download
import React from 'react'

function Topsec() {
  return (
    <>
<div className=' con-bg grid h-full pt-48 sm:pt-60  relative pb-10 sm:p-48 place-items-center'>
          <div>
    <h1 className=' text-center text-white font-[800] md:text-[56px] text-[24px] sm:text-[32px] pb-3'>Get in touch with us</h1>
    <p className=' text-center text-white font-[700] md:text-[20px] text-[16px]  '>Want to get in touch? We'd love to hear from you. Here's how you can reach us...</p>
    </div>
    </div>
   
    </>
    
  )
}

export default Topsec
const phoneValidation= /^([\s\(\)\-]*\d[\s\(\)\-]*){8}$/
const emailValidation= /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const passwordValidation = /^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]{8,}$/
const passwordValidationMessage = "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"

const BASE_LINK_URL = 'https://sandbox.dashboard.zouse.co'
const Constant = {
    phoneValidation,
    emailValidation,
    passwordValidation,
    passwordValidationMessage,
    BASE_LINK_URL
}

export default Constant
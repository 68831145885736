import React from 'react'
import Sphone from '../../Assets/images/investTop.png'
import Phone from '../../Assets/images/investTop.png'
import Constant from '../../Constant'
import Button from '../SelectValue/Button'
function TopSec() {
  return (
    <div className=' pt-20  md:px-0   w-full'>
        <div className='  grid lg:grid-cols-2 gap-4'>
            <div className='md:flex-col wow  animate__animated animate__fadeInLeft flex justify-center  md:pl-10  pt-6 '>
            <div>
               
                <div className=' mt-6 '>
                    <h1 className=' text-[24px] md:text-[48px] font-[800]'>Find the perfect property from a wide selection of listings</h1>
                    </div>
                    <p className=' text-[#1A1A1B] '>"Explore a World of Possibilities - Browse Listings by Location, Price, and Amenities"</p>
                 <div className=' mt-10 md:flex'> 
                    <div className='flex justify-center mt-3 md:mt-0 pl-3'>
                    <a href={Constant.BASE_LINK_URL + '/'} target='_blank' rel="noreferrer">
                       <Button
                        name='Start now'
                       />
                       </a>
                    </div>
                   
                </div>
            </div>
            </div>
            <div className=' md:pt-[52px] relative flex justify-center '>
                    <img src={Phone} className='hidden wow animate__zoomInUp animate__animated md:block ' alt='Phone'/>
                    <img src={Sphone} className='md:hidden wow animate__zoomInUp animate__animated block ' alt='Phone'/>
            </div>
        </div>
    </div>
  )
}

export default TopSec
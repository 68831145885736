import React from 'react'
import Sphone from '../../Assets/images/investTop.png'
import Constant from '../../Constant'
import Button from '../SelectValue/Button'
function TopSec() {
  return (
    <div className=' pt-20 px-2 md:px-0   w-full'>
        <div className=' px-5  md:px-10  grid md:grid-cols-2 gap-4'>
            <div className=' wow   animate__animated animate__fadeInLeft flex md:flex-col justify-center  pb-4 '>
            <div>
               
                <div className=' mt-6 '>
                    <h1 className=' text-[24px] text-center md:text-start mb-2 lg:text-[48px] font-[800]'>Invest in projects secured by <span className=' text-[#00BC67]'>Real estate</span> </h1>
                    </div>
                    <p className=' text-[#1A1A1B] '>All investment opportunities have been subject to a rigorous due diligence process. Only about 5% on them will make it to our investors</p>
                 <div className=' mt-10 md:flex'> 
                    <div className='flex justify-center mt-3 md:mt-0 pl-3'>
                    <a href={Constant.BASE_LINK_URL + '/'} target='_blank' rel="noreferrer">
                       <Button
                        name='Get started'
                       />
                       </a>
                    </div>
                   
                </div>
            </div>
            </div>
            <div className=' md:pt-[52px] relative flex justify-center '>
                    <img src={Sphone} className=' wow animate__zoomInUp animate__animated block ' alt='Phone'/>
            </div>
        </div>
    </div>
  )
}

export default TopSec
import React, { useState } from 'react'
import {  useLoadScript,} from "@react-google-maps/api";
import Map from '../About/Map';
import ContactCom from '../About/ContactCom';
import LocationMap from './Mapsam';
import LocationMap2 from './Mapsam2';
function Contactus() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBRA8q3YhyqUVvMk-YDJAV_uLBGub0SCTA",
  });
  // const [latitude, setLatitude] = useState(6.578976); // Initial latitude
  // const [longitude, setLongitude] = useState(3.38542);
  const [lagosC, setLagosC] = useState(true)
   const [illorin, setIllorinC] = useState(false)
   const handleChangeIllorin = () =>{
    setIllorinC(true)
     setLagosC(false)
   
   }
   const handleChangeLagos = () =>{
     setIllorinC(false)
    setLagosC(true)
    
  }
  if (!isLoaded) return <div>Loading...</div>;

  return <div className=' '> 
  <div className=' sm:w-[80%] flex mx-auto mt-4 p-5   border border-gray-200  rounded-[12px] bg-white'>
  <div className=' wow  grid sm:grid-cols-2 md:grid-cols-3 gap-4  animate__animated animate__zoomIn'>
  <div className=' '>
    
    <div >
    <ContactCom
      name='Lagos'
      address=' No 91 Ogodu road, Ojota Lagos.'
      email='hello@zouse.co'
      pNum='08123945301, 09082171728'
     
      bool={lagosC}
      change = {handleChangeLagos}
    />
     <ContactCom
      name='Kwara'
      address='121 Olanrewaju Building Muritala way  Post Office Road, Ilorin, Kwara State, Nigeria'
      email='hello@zouse.co'
      pNum='08171299359, 09082171728'
      bool={illorin}
      change = {handleChangeIllorin}
    />
       
    </div>
  </div>
  <div className='  md:col-span-2 w-full h-[400px]  mx-auto'>
  {/* <Map
    area={lagosC ? { lat: 6.578976, lng: 3.38542 }: { lat: 8.485017, lng: 4.563801 }}
  /> */}
  {lagosC ? 
  <LocationMap latitude={6.578976} longitude={3.38542} />:
  <LocationMap2 latitude={8.485017} longitude={4.563801} />
  }
  {/* <LocationMap latitude={latitude} longitude={longitude} /> */}
  </div>
  </div>
  </div>
  </div>
  
}

export default Contactus
import React, { useEffect } from 'react'
import Number from '../../Components/Home/Number'
import TopSec from '../../Components/Home/TopSec'
import Offers from '../../Components/Home/Offers'
import Investment from '../../Components/Home/Investment'
import Why from '../../Components/Home/Why'
import Download from '../../Components/Home/Download'
import Rating from '../../Components/Home/Rating'
import NavBar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import WOW from 'wowjs';
import HowInvest from '../../Components/Home/HowInvest'
import BuyInvest from '../../Components/Home/BuyInvest'
import RaiseFund from '../../Components/Home/RaiseFund'
import ListingPro from '../../Components/Home/List'
function Home() {
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])
  return (
    <div>
        <NavBar/>
        <div className=' px-2 md:px-20'>
            <TopSec/>
           
           
        </div>
        <Why/>
        <HowInvest/>
       
        <BuyInvest/>
        <RaiseFund/>
        <ListingPro/>
        <div className=' px-2 md:px-20'>
        {/* <Offers/> */}
            <Investment/>
        </div>
       
        <div className=' '>
                
                <Rating/>
        </div>
       
        <Footer/>
    </div>
  )
}

export default Home